<div class="register__container">   
    <div class="card">

        <h5 class="card-header info-color white-text text-center py-4">
          <strong>Registro</strong>
        </h5>
      
        <!--Card content-->
        <div class="card-body px-lg-5 pt-0">
      
          <!-- Form -->
            <form class="text-center" ngNativeValidate (ngSubmit)="registro()">
        
                <div class="md-form">
                    <label for="materialLoginFormEmail">Nombre / empresa</label>
                    <input 
                        type="text" placeholder="Nombre / empresa *" class="form-control" required
                        name="nombre"
                        [(ngModel)]="nombre">
                </div>
                <div class="md-form">
                    <label>Correo</label>
                    <input 
                        type="email" placeholder="correo *" class="form-control" required
                        name="email"
                        [(ngModel)]="email">
                </div>
        
                <!-- Password -->
                <div class="md-form">
                    <label>Contraseña</label>
                    <input 
                        type="password" placeholder="clave *" class="form-control" minlength="6" maxlength="10" required
                        name="pass"
                        [(ngModel)]="pass">
                    
                </div>
        
                <div class="d-flex justify-content-around">
                    <div>
                        <div class="form-check">
                            <input (click)="toggle_tyc()" type="checkbox" class="form-check-input" required>
                            <label class="form-check-label" for="materialLoginFormRemember">Acepto los <a href="./tyc">Términos y condiciones</a></label>
                        </div>
                    </div>
                    <div>
                        <a href="./adp">Aviso de privacidad</a>
                    </div>
                </div>
        
                <!-- Sign in button -->
                <button class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit">Registrarse</button>
        
                <!-- Register -->
                <p>¿Ya tiene una cuenta?
                <a href="./ingreso">Ingresar</a>
                </p>
        
                <!-- Social login -->
                <!--
                <p>inicie sesión con:</p>
                <a type="button" class="btn-floating btn-fb btn-sm">
                <i class="fa fa-facebook-f"></i>
                </a>
                <a type="button" class="btn-floating btn-tw btn-sm">
                <i class="fa fa-google"></i>
                </a>-->
            </form>
          <!-- Form -->
      
        </div>
      
      </div>

</div>
