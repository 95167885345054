import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Categoria} from '../../models/categoria';
import {Producto} from '../../models/producto';
import {ProductoService} from "../../services/productos/products.service";

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.less']
})
export class ProductoComponent implements OnInit {
  type: string;
  productos: Producto[];
  categorias: Categoria[];
  constructor(private router:ActivatedRoute, public productoservice: ProductoService ) { }

  ngOnInit(): void {
    this.router.params.subscribe(params=>{
      this.type = params["type"];
      if(this.type==="todo"){
        this.productoservice.all().subscribe((prods:Producto[])=>{
          this.productos = prods;
        });
      } else{
        this.productoservice.obtener_por_categoria(this.type).subscribe((prods:Producto[])=>{
          this.productos = prods;
        });
      }
      this.productoservice.all_Categories().subscribe((categs:Categoria[])=>{
        this.categorias = categs;
        if(!this.type)  this.type = "todo";
        this.categorias.map((categ:Categoria)=>{
          if(categ.filtro === this.type){
            categ.active = true;
          } else{
            categ.active = false;
          }
        });
      });
    });
  }

}
