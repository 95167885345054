import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {Producto} from "../../models/producto";
import {ProductoService} from "../../services/productos/products.service";
@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.less']
})
export class PortadaComponent implements OnInit {
  destacados: Producto[];
  constructor(private router:Router, public productoservice: ProductoService) { }

  ngOnInit(): void {
    this.productoservice.most_wanted().subscribe( (hot:Producto[])=>{
      this.destacados = hot;
    });
  }
  producto_selected(producto:Producto): void{
    this.router.navigate(["/productos","preview",producto.sku]);
  }
}
