<h1>{{type | titlecase}}</h1>
<div class="row">
    <div class="col col-lg-12">
        <img class="img-responsive" width="100%" src="assets/images/home/TIENDA-04.jpg">
    </div>
</div>
<hr>
<div class="row">
    <div class="col-md-2 sidemenu">
        <app-menu-productos type={{type}} [categorias]=categorias></app-menu-productos>
    </div>
    <div class="col-md-10">
        <div class="row --sticky">
            <div class="col-sm-8">
                <ol class="breadcrumb bg-light">
                    <li class="breadcrumb-item"><a href="#">{{type|titlecase}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Todo</li>
                </ol>
            </div>
            <div class="col-sm-4">
                <nav class="nav navbar-expand-lg navbar-light bg-light">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="sort_menu" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="fa fa-sort"></span>
                                Ordenar
                            </a>
                            <div class="dropdown-menu" aria-labelledby="sort_menu">
                                <a class="nav-link">
                                    Menor precio
                                </a>
                                <a class="nav-link" href="#">
                                    Mayor precio
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="nav-link" href="#">
                                    Más vendido
                                </a>
                                <a class="nav-link" href="#">
                                    Por nombre
                                </a>
                            </div>
                        </li>
                        <li class="pull-right">
                            <a class="nav-link">
                                <span class="badge badge-secondary">{{productos.length}} Productos</span> 
                            </a>
                            
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="row catalogo" style="min-height:50vh">
            <app-catalogo-mosaico [productos]=productos></app-catalogo-mosaico>
        </div>
    </div>
</div>

