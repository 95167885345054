<div class="row catalogo">
    <div *ngFor="let producto of productos" class="col-4">
        <div class="card producto">
            <img class="card-img" (click)="open_preview(producto)" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg" alt="Producto">
            <div class="card-body">
              <h4 class="card-title">{{producto.nombre}}</h4>
              <h6 class="card-subtitle mb-2 text-muted">SKU: {{producto.sku}}</h6>
              <p class="card-text">{{producto.categoria}}</p>
              <div class="buy d-flex justify-content-between align-items-center">
                <div class="price text-success"><h6 class="mt-4">$ {{producto.precio.toFixed(2)}}</h6>
                </div>
              </div>
            </div>
            <div class="card-footer row">
                <div class="col" (click)="add_to_cart(producto)">
                    <button href="#" class="btn btn-success pull-right"><i class="fa fa-shopping-bag"></i> Agregar</button>
                </div>
            </div>
        </div>
    </div>
</div>