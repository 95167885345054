import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {UsersService} from '../../services/users/users.service';

declare let alertify: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  email: String;
  clave: String;
  constructor(public userService: UsersService, private router: Router) { }

  ngOnInit(): void {
    
  }

  login(){
    this.email = this.email.trim();
    if(!this.email.length){
      alertify.error("Se requiere su correo para ingresar.");
      return;
    }
    this.clave = this.clave.trim();
    if(!this.clave.length){
      alertify.error("Se requiere su correo para ingresar.");
      return;
    }
    this.userService.login({"correo":this.email, "password": this.clave}).subscribe(response=>{
      if(response.done){
        alertify.success("Acceso concedido. ¡Bienvenid@!");
        sessionStorage.setItem("userid", response.result.id);
        sessionStorage.setItem("username", response.result.correo);
        sessionStorage.setItem("name", response.result.nombre)
        this.router.navigate(["/"]);
      } else{
        if(response.code == "fail"){
            alertify.alert(response.error);
        } else{
            alertify.alert(response.error+". Verifique su email y clave e intente de nuevo por favor.");
            
        }
      }
    })
  }

}
