import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../services/users/users.service';
import {User} from '../../models/user';
import {Router} from "@angular/router";

declare let alertify: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit {
  nombre: string;
  email: string;
  pass:string;
  user:User;
  acepto:Boolean = false;
  constructor(public userService: UsersService, private router: Router) { }

  ngOnInit(): void {
  }

  toggle_tyc(){
    this.acepto = !this.acepto;
  }
  registro(){
    if(!this.acepto){
      alertify.error("Debe aceptar los TÈRMINOS Y CONDICIONES para poder hacer uso del sistema.");
      return;
    }
    this.user = {id: null, nombre: this.nombre, correo: this.email, password: this.pass, tipo: null};
    this.userService.registro(this.user).subscribe(response=>{
      if(response.done){
        alertify.success("usuario registrado correctamente");
        this.router.navigate(["/ingreso"],{queryParams:{"correo": this.email}});
      } else{
        if(response.code == "fail"){
          if(response.data.errno == 1062){
            alertify.error(`El correo ${this.email} ya existe. Por favor inicie sesión o restablezca su contraseña.`);
          } else{
            alertify.error(response.data.code);
          }
        } else{
          var campos = [];
          for(var campo in response.data){
            campos.push(response.data[campo]);
          }
          alertify.error("No se pudo registrar correctamente:<br><pre>"+ campos.join("<br>")+"</pre>");
        }
      }
    });
  }
}
