import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Categoria } from 'src/app/models/categoria';
import { Producto } from 'src/app/models/producto';
import { Proveedor } from 'src/app/models/proveedor';
import { ProductoService } from 'src/app/services/productos/products.service';

@Component({
  selector: 'app-menu-proveedor',
  templateUrl: './menu-proveedor.component.html',
  styleUrls: ['./menu-proveedor.component.less']
})
export class MenuProveedorComponent implements OnInit {
  @Input() id_proveedor: number;
  @Input() categorias:Categoria[];
  constructor(private route:ActivatedRoute, private router:Router, public producto_srv:ProductoService) { }

  ngOnInit(): void {

  }

}
