<div class="login__container">   
    <div class="card">

        <h5 class="card-header info-color white-text text-center py-4">
          <strong>Ingreso</strong>
        </h5>
      
        <!--Card content-->
        <div class="card-body px-lg-5 pt-0">
      
          <!-- Form -->
          <form class="text-center" ngNativeValidate (ngSubmit)="login()">
      
            <!-- Email -->
            <div class="md-form">
              <label for="materialLoginFormEmail">Correo</label>
              <input type="email" placeholder="correo *" required="true" class="form-control"
                name="email"
                [(ngModel)]="email">
              
            </div>
      
            <!-- Password -->
            <div class="md-form">
              <label for="materialLoginFormPassword">Contraseña</label>
              <input type="password" placeholder="clave *" required="true" minlength="6" maxlength="10" class="form-control"
              name="clave" [(ngModel)]="clave">
              
            </div>
      
            <div class="d-flex justify-content-around">
              <!--<div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="materialLoginFormRemember">
                  <label class="form-check-label" for="materialLoginFormRemember">Recordarme</label>
                </div>
              </div>-->
              <div>
                <!-- Forgot password -->
                <a href="">Olvidé mi contraseña</a>
              </div>
            </div>
      
            <!-- Sign in button -->
            <button  class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit">Acceder2</button>
      
            <!-- Register -->
            <p>¿No tiene una cuenta?
              <a href="./registro">Regístrarse</a>
            </p>
      
            <!-- Social login -->
            <!--<p>or sign in with:</p>
            <a type="button" class="btn-floating btn-fb btn-sm">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a type="button" class="btn-floating btn-tw btn-sm">
              <i class="fab fa-twitter"></i>
            </a>
            <a type="button" class="btn-floating btn-li btn-sm">
              <i class="fab fa-linkedin-in"></i>
            </a>
            <a type="button" class="btn-floating btn-git btn-sm">
              <i class="fab fa-github"></i>
            </a> -->
      
          </form>
          <!-- Form -->
      
        </div>
      
      </div>

</div>
