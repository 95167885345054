import { Component, OnInit } from '@angular/core';
import {ComprasService} from '../../services/compras/compras.service';
import {ActivatedRoute} from "@angular/router";
declare let alertify:any;
@Component({
  selector: 'app-gracias',
  templateUrl: './gracias.component.html',
  styleUrls: ['./gracias.component.less']
})
export class GraciasComponent implements OnInit {
  id_compra: string;
  constructor(private route:ActivatedRoute, public compras:ComprasService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.id_compra = params["id_orden"];
    });
  }

  download(){
    this.compras.obtener_orden( encodeURIComponent(this.id_compra) ).subscribe(pdf=>{
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(pdf)
        a.href = objectUrl
        a.download = this.id_compra+'.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);
        alertify.success("Archivo descargado correctamente.");
    });
  }

}
