import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import { Categoria } from 'src/app/models/categoria';
import { Proveedor } from 'src/app/models/proveedor';
import {ProductoService} from '../../services/productos/products.service';

@Component({
  selector: 'app-lista-proveedores',
  templateUrl: './lista-proveedores.component.html',
  styleUrls: ['./lista-proveedores.component.less']
})
export class ListaProveedoresComponent implements OnInit {
  id_categoria:number;
  categoria: Categoria;
  proveedores: Proveedor[];
  constructor(private route:ActivatedRoute, private router:Router, public producto_srv:ProductoService) { }

  ngOnInit(): void {
    this.route.params.subscribe({
      next:(params:any)=>{
        this.id_categoria =  parseInt(params["id_categoria"]);
        console.log("id_categoria", this.id_categoria);
        this.producto_srv.obtener_categoria_byid(this.id_categoria).subscribe({
          next:(categoria:Categoria)=>{
            this.categoria = categoria;
            console.log("categoria", categoria)
            this.producto_srv.obtener_provedores_bycategoria(this.id_categoria).subscribe({
              next:(proveedores:Proveedor[])=>{
                this.proveedores = proveedores;
                console.log("proveedores", this.proveedores)
              }
            });
          }
        })
      }
    })
  }

  selected(proveedor:Proveedor){
    console.log("proveedor", proveedor);
    this.router.navigate(["productos","proveedor",proveedor.id])
  }

}
