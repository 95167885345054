// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import {environment} from "../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class EmailService {
  
  constructor(private http: HttpClient) {
  }
  enviar_compra(cliente:string, orden:any): Observable<any> {
    return this.http.post(`${environment.api_url}/correos`, {"destinatario":cliente, "orden": orden});
  }

  enviar_ordenes(ordenes:any): Observable<any> {
    return this.http.post(`${environment.api_url}/correos/ordenes`, {"ordenes": ordenes});
  }
  
}