import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {ProductoService} from "../../services/productos/products.service";
import {Producto} from "../../models/producto";
declare let alertify:any;
@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.less']
})
export class ProductPreviewComponent implements OnInit {
  sku:String;
  producto: any;
  constructor(private route:ActivatedRoute, private router:Router, public productoservice:ProductoService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.sku = params["sku"];
      this.productoservice.obtener_by_sku(this.sku).subscribe((producto)=>{
        this.producto = producto;
      })
    })
  }

  selected_image(target){

  }

  add_to_cart(producto:Producto){
    //localStorage.clear(); return;
    var cart_data = localStorage.getItem("cart_data");
    var cart = null;
    if(!cart_data){
      cart = {
        "productos":[{
          "producto": producto,
          "cantidad": 1
        }]
      };
    } else{
      cart = JSON.parse(cart_data);
      let exists = false;
      for(var stored of cart.productos){
        if(producto.id === stored.producto.id){
          stored.cantidad ++;
          exists = true;
        }
      }
      if(!exists){
        cart.productos.push({
          "producto": producto,
          "cantidad": 1
        });
      }
    }
    localStorage.setItem("cart_data", JSON.stringify(cart));
    alertify.success("Se agregó a su bolsa");
  }

  goback(){
    this.router.navigate(["/","productos"]);
  }
}
