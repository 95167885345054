import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Producto} from '../../models/producto';
declare let alertify:any;
@Component({
  selector: 'app-catalogo-mosaico',
  templateUrl: './catalogo-mosaico.component.html',
  styleUrls: ['./catalogo-mosaico.component.less']
})
export class CatalogoMosaicoComponent implements OnInit {
  type: string;
  @Input() productos: Producto[];
  constructor(private route:ActivatedRoute, private router:Router){ }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.type = params['type'];
    });
  }

  add_to_cart(producto:Producto){
    //localStorage.clear(); return;
    var cart_data = localStorage.getItem("cart_data");
    var cart = null;
    if(!cart_data){
      cart = {
        "productos":[{
          "producto": producto,
          "cantidad": 1
        }]
      };
    } else{
      cart = JSON.parse(cart_data);
      let exists = false;
      for(var stored of cart.productos){
        if(producto.id === stored.producto.id){
          stored.cantidad ++;
          exists = true;
        }
      }
      if(!exists){
        cart.productos.push({
          "producto": producto,
          "cantidad": 1
        });
      }
    }
    localStorage.setItem("cart_data", JSON.stringify(cart));
    alertify.success("Se agregó a su bolsa");
  }

  open_preview(producto: Producto): void{
    this.router.navigate(["productos","preview",producto.sku]);
  }

  ngOnDestroy(): void{

  }

}
