<hr>
<footer>
    <div class="row">
        <div class="col-md-3">
            <p>Empresa</p>
            <ul>
                <li><a class="">Quiénes somos</a></li>
                <li><a class="">Aviso de privacidad</a></li>
                <li><a class="">Políticas de uso</a></li>
            </ul>
        </div>
        <div class="col-md-3">
            <p>Contacto</p>
            <ul>
                <li><a class="">Escríbenos</a></li>
                <li><a class="">Llamanos</a></li>
                <hr>
                <li><a class="">Para empresas</a></li>
            </ul>
        </div>
        <div class="col-md-3">
            <p>Inscribete</p>
            <ul>
                <li>
                    <form class="form-inline">
                        <input class="form-control mr-sm-2" type="email" placeholder="e-mail" aria-label="Email">
                        <button class="btn btn-outline-secondary my-2 my-sm-0" type="submit" title="clic para buscar"><span class="fa fa-check"></span></button>
                    </form>
                </li>
            </ul>
            
        </div>
        <div class="col-md-3">
            <p>Síguenos</p>
            <span class="fa-stack">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-facebook fa-stack-1x fa-inverse"></i>
            </span>
            <span class="fa-stack">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-twitter fa-stack-1x fa-inverse"></i>
            </span>
            <span class="fa-stack">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-instagram fa-stack-1x fa-inverse"></i>
            </span>
        </div>
    </div>
    <div class="row bg-light">
        <div class="col-md-2">
            <p>&copy;2020 Shoppingstore</p>
            <hr>
        </div>
        <div class="col">
            <ol class="breadcrumb bg-light">
                <li class="breadcrumb-item"><a href="#">Inicio</a></li>
                <li class="breadcrumb-item"><a href="#">Empresa</a></li>
                <li class="breadcrumb-item"><a href="#">Contacto</a></li>
            </ol>
        </div>
    </div>
</footer>