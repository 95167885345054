import { Component, OnInit} from '@angular/core';
declare let alertify: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit{
  title = 'Shopping store';
  constructor() { 
  }
  number2amount(number, moneysign="$"){
    var val = number.toString();
    var var_array = Array.from(val).reverse();
    var value = var_array.join("");
    var val_groups = value.match(/.{1,3}/g);
    var formated = val_groups.map(group=>Array.from(group).reverse().join(""))
    return moneysign + formated.reverse().join(",");
  }
  runSuccess(){
    alertify.success("success");
  }
  ngOnInit(): void {

  }
}
