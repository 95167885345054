<div class="register__container">   
    <div class="card">
        <h5 class="card-header info-color white-text text-center py-4">
            <strong>Cuenta</strong>
        </h5>
        <div class="card-body px-lg-5 pt-0">
            <form class="text-center" ngNativeValidate (ngSubmit)="actualizar()">
        
                <div class="md-form">
                    <label for="materialLoginFormEmail">Nombre / empresa</label>
                    <input 
                        type="text" placeholder="Nombre / empresa *" class="form-control" required
                        name="nombre"
                        [(ngModel)]="nombre">
                    
                </div>
                <div class="md-form">
                    <label>Correo</label>
                    <input 
                        type="email" placeholder="correo *" class="form-control" required
                        name="email"
                        [(ngModel)]="email">
                    
                </div>
                <div class="md-form">
                    <label>Contraseña</label>
                    <input 
                        type="password" placeholder="clave *" class="form-control" minlength="6" maxlength="10" required
                        name="clave"
                        [(ngModel)]="clave">
                    
                </div>
                <div class="form-group">
                    <button class="btn btn-outline-info btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit">Guardar</button>
                    <button (click)="logout()" class="btn btn-outline-danger btn-rounded btn-block my-4 waves-effect z-depth-0" type="button">Cerrar sesión</button>
                </div>
            </form>      
        </div>
    </div>
</div>
