import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { by } from 'protractor';
import { Proveedor } from 'src/app/models/proveedor';
import { ProductoService } from 'src/app/services/productos/products.service';
import {ComprasService} from '../../services/compras/compras.service';
import {EmailService} from '../../services/email/email.service';

declare let alertify: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html'/*,
  styleUrls: ['./checkout.component.less']*/
})
export class CheckoutComponent implements OnInit {
  cart: any[];
  correo:string;
  nombre:string;
  fecha_compra:string;
  cart_byprov:any;
  constructor(private router: Router, public compras:ComprasService, public email:EmailService, public productoServ: ProductoService) { }

  async ngOnInit(): Promise<any> {
    let userid = sessionStorage.getItem("userid");
    if(!userid){
      alertify.error("No se encontró un sesión activa");
      alertify.alert("Antes de ordenar se requiere que tenga una sesión activa para poder enviarle su orden de compra.")
      this.router.navigate(["/ingreso"]);
    } else{
      try {
        var cart_data = localStorage.getItem("cart_data");
        this.cart = JSON.parse(cart_data);
        this.cart["total"]= 0;
        this.cart["productos"].map((item:any)=>{
          this.cart["total"] += item.producto.precio * item.cantidad;
        });
        this.correo = sessionStorage.getItem("username");
        this.nombre = sessionStorage.getItem("name");
        this.fecha_compra = new Date().toLocaleDateString();
        var byproveedor = {};
        this.cart["productos"].map(item=>{
            let idprov = item.producto.id_proveedor.toString();
            typeof(byproveedor[idprov]) === "undefined" && (byproveedor[idprov] = {"proveedor": null, productos:[], total:null});
            byproveedor[idprov]["productos"].push(item);
        }); 

        this.cart_byprov = [];
        for(var id_proveedor in byproveedor){
          let prov = await this.productoServ.obtener_provedor_byid(parseInt(id_proveedor));
          byproveedor[id_proveedor]["proveedor"]= prov;
          this.cart_byprov.push(byproveedor[id_proveedor]);
        }
        this.cart_byprov.map(prov=>{
          prov["total"]= 0;
          prov["productos"].map(item=>{
            prov["total"] += item["producto"].precio *  item.cantidad;
          });
        });
      } catch (tryerror) {
        console.error(tryerror);
        this.cart= [];
      }
    }
    
  }

  regresar(){
    this.router.navigate(["/cart"]);
  }

  checkout(){
    const contain = $("app-checkout").get(0).firstChild.childNodes.item(1) as HTMLElement;
    const checkouts = $("app-checkout").children().first().find(".checkouts").children(".facturas").first();
    let facturas = []
    $(checkouts).children(".factura").each(function(){
      let id_proveedor = $(this).data("proveedor");
      facturas.push( {"id": id_proveedor, "content": $(this).get(0).outerHTML } )
    });
    const checkoutall = $("app-checkout").children().first().find(".checkoutall").first();
    let orden = {"id": sessionStorage.getItem("userid"), "content": checkoutall.get(0).outerHTML};
    var self = this;
    this.compras.crear_orden(orden, facturas ).subscribe(async(response)=>{
      if(response.done){
        self.email.enviar_compra(this.correo, response.result.orden).subscribe(async(enviar_compra_done)=>{
          for(var orden of response.result.facturas){
            let prov = await this.productoServ.obtener_provedor_byid(parseInt(orden.id));
            orden["destinatario"] = prov.correo;
          }
          self.email.enviar_ordenes(response.result.facturas).subscribe(email_result=>{
            if(email_result.done){
              alertify.success("Orden enviada correctamente.");
            } else{
              alertify.error("No pudimos enviar el correo electrónico. Puede descargarlo.")
              //alertify.alert(email_result.error.response);
            }
            this.router.navigate(["cart","checkout",response.result.orden.orden_id]);
          });
        })
        alertify.success("Orden creada correctamente.");
      }
    });
  }

  number2amount(number, moneysign="$"){
    var val = number.toFixed(2).toString();
    var var_array = Array.from(val).reverse();
    var value = var_array.join("");
    var val_groups = value.match(/.{1,3}/g);
    var formated = val_groups.map(group=>Array.from(group).reverse().join(""))
    var dot = moneysign + formated.reverse().join(",");
    var dotparts = dot.split(".");
    var dot_left = dotparts[0].substring(0, dotparts[0].length-1);
    return  dot_left+"."+dotparts[1];
  }

}
