
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuProductosComponent } from './components/menu-productos/menu-productos.component';
import { PortadaComponent } from './pages/portada/portada.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CatalogoMosaicoComponent } from './components/catalogo-mosaico/catalogo-mosaico.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ProductoComponent } from './components/producto/producto.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductPreviewComponent } from './components/product-preview/product-preview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TerminosycondicionesComponent } from './pages/terminosycondiciones/terminosycondiciones.component';
import { AvisoprivacidadComponent } from './pages/avisoprivacidad/avisoprivacidad.component';
import { CuentaComponent } from './pages/cuenta/cuenta.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { GraciasComponent } from './pages/gracias/gracias.component';
import { ListaProveedoresComponent } from './components/lista-proveedores/lista-proveedores.component';
import { MenuProveedorComponent } from './components/menu-proveedor/menu-proveedor.component';
import { ProductosProveedorComponent } from './pages/productos-proveedor/productos-proveedor.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuProductosComponent,
    PortadaComponent,
    CarouselComponent,
    CatalogoMosaicoComponent,
    NotfoundComponent,
    ProductoComponent,
    FooterComponent,
    ProductPreviewComponent,
    LoginComponent,
    SignupComponent,
    TerminosycondicionesComponent,
    AvisoprivacidadComponent,
    CuentaComponent,
    CartComponent,
    CheckoutComponent,
    GraciasComponent,
    ListaProveedoresComponent,
    MenuProveedorComponent,
    ProductosProveedorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
