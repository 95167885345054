<nav>
  <div class="main_menu__container">
    <div><a class="logo" href="/">SHOPPINGSTORE</a></div>
    <div class="--right">
      <ul class="menu">
        <li><a href="#/cuenta"><span class="fa fa-2x fa-user"></span></a></li>
        <li><a id="#mainbag" href="#/cart"><span class="fa fa-2x fa-shopping-bag"></span></a></li>
      </ul>
    </div>
  </div>
  <div class="main_menu__container">
    <div>
      <ul class="menu">
        <!--<li><a href="#" routerLink="/productos/">Todas</a></li>-->
        <li *ngFor="let categoria of secciones">
          <a href="#" routerLink="/{{categoria.destino}}/{{categoria.ancla}}">{{categoria.etiqueta}}</a>
        </li>
        <!--<li><a href="#">Mujer</a></li>
        <li><a href="#">Hombre</a></li>
        <li><a href="#">Belleza</a></li>
        <li><a href="#">Hogar</a></li>
        <li><a href="#">Infantil</a></li>
        <li><a href="#">Liquidación</a></li>-->
      </ul>
    </div>
    <div class="--right">
      <ul class="menu">
        <li><input class="form-control" type="search" placeholder="Buscar"></li>
      </ul>
    </div>
  </div>
</nav>