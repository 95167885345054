// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {environment} from "../../../environments/environment";
import {User} from '../../models/user'; 
@Injectable({
  providedIn: "root"
})
export class UsersService {
  constructor(private http: HttpClient) {}

  login(user: any): Observable<any> {
    return this.http.post(`${environment.api_url}/login`, user);
  }
  registro(user: User): Observable<any> {
    return this.http.post(`${environment.api_url}/usuarios`, user);
  }
  obtener(id): Observable<any> {
    return this.http.get(`${environment.api_url}/usuarios/${id}`);
  }
}