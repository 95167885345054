
<div class="row justify-content-center">
    <div class="d-flex">
        <div class="card">
            <div class="d-flex flex-column thumbnails">
                <div (click)="selected_image($event.target.dataset)" id="f1" class="tb tb-active"> <img data-target="f11" class="thumbnail-img fit-image" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
                <div id="f2" class="tb"> <img data-target="f21" class="thumbnail-img fit-image" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
                <div id="f3" class="tb"> <img data-target="f31" class="thumbnail-img fit-image" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
                <div id="f4" class="tb"> <img data-target="f41" class="thumbnail-img fit-image" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
            </div>
            <fieldset id="f11" class="active">
                <div class="product-pic"> <img class="pic0" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
            </fieldset>
            <fieldset id="f21" class="">
                <div class="product-pic"> <img class="pic0" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
            </fieldset>
            <fieldset id="f31" class="">
                <div class="product-pic"> <img class="pic0" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
            </fieldset>
            <fieldset id="f41" class="">
                <div class="product-pic"> <img class="pic0" src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg"> </div>
            </fieldset>
        </div>

        <div class="card producto">
          <div class="card-body">
            <h4 class="card-title">{{producto.nombre}}</h4>
            <h6 class="card-subtitle mb-2 text-muted">SKU: {{producto.sku}}</h6>
            <p class="card-text">{{producto.categoria}}</p>
            <div class="buy d-flex justify-content-between align-items-center">
              <div class="price text-success"><h4 class="mt-4">$ {{producto.precio}}</h4>
              </div>
            </div>
          </div>
          <div class="card-footer row">
              <div class="col pull-right">
                  <button (click)="goback()" href="#" class="btn btn-light">Regresar</button>
                  <button (click)="add_to_cart(producto)" href="#" class="btn btn-success ml-auto"><i class="fa fa-shopping-bag"></i> Agregar</button>
              </div>
          </div>
      </div>
    </div>
</div>