// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import {environment} from "../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class ComprasService {
  
  constructor(private http: HttpClient) {
  }

  obtener_orden(id_compra:string): Observable<any>{
    return this.http.get(`${environment.api_url}/compras/${id_compra}` , {responseType: 'blob'});
  }

  crear_orden(orden: any, facturas : any): Observable<any> {
    return this.http.post(`${environment.api_url}/compras`, {"orden": orden, "facturas": facturas});
  }
  
}