<div class="d-flex tex-center">
    <div class="mx-auto">
        <h1>¡Gracias por su compra!</h1>
        <p>Hemos enviado su orden de compra a su correo electrónico.</p>
        <hr>
        <div class="d-flex">
            <button class="btn btn-light"><a href="/">Seguir explorando</a></button>
            <button (click)="download()" class="btn btn-success">Descargar PDF</button>
        </div>
        
    </div>
</div>