import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
declare let alertify : any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {
  cart: any[];
  constructor(private router: Router) { }

  ngOnInit(): void {
    try {
      var cart_data = localStorage.getItem("cart_data");
      this.cart = JSON.parse(cart_data);
      this.cart["total"]= 0;
      this.cart["productos"].map((item:any)=>{
        this.cart["total"] += item.producto.precio * item.cantidad;
      });
    } catch (error) {
      this.cart= [];
    }
  }

  removeFromCart(target_item:any){
    alertify.confirm("Eliminar producto","¿Seguro que desea eliminar este producto?", ()=>{
      let index = this.cart["productos"].findIndex(item =>{
        return Object.is(target_item, item)
      });
      this.cart["productos"].splice(index, 1);
      this.cart["total"]= 0;
      this.cart["productos"].map((item:any)=>{
        this.cart["total"] += item.producto.precio * item.cantidad;
      });
      localStorage.setItem("cart_data", JSON.stringify(this.cart));
    }, function(cancel){

    });
    
  } 

  vaciar(){
    alertify.confirm("Vaciar bolsa","¿Seguro que desea vaciar su bolsa?. Perderá la oportunidad de llevarse esos grandiosos productos y no se puede deshacer esta opción.", ()=>{
        this.cart["productos"].splice(0);
        localStorage.removeItem("cart_data");
    });
  }

  comprar(){
    this.router.navigate(["/cart/checkout"]);
  }

  number2amount(number, moneysign="$"){
    var val = number.toFixed(2).toString();
    var var_array = Array.from(val).reverse();
    var value = var_array.join("");
    var val_groups = value.match(/.{1,3}/g);
    var formated = val_groups.map(group=>Array.from(group).reverse().join(""))
    var dot = moneysign + formated.reverse().join(",");
    var dotparts = dot.split(".");
    var dot_left = dotparts[0].substring(0, dotparts[0].length-1);
    return  dot_left+"."+dotparts[1];
  }

}
