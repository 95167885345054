// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import {environment} from "../../../environments/environment";
import {Producto} from '../../models/producto'; 
import {Categoria} from '../../models/categoria'; 
import {Seccion} from '../../models/seccion'; 
import {Proveedor} from '../../models/proveedor'; 
@Injectable({
  providedIn: "root"
})
export class ProductoService {
  productos: Producto[];
  categorias: Categoria[];
  secciones: Seccion[];
  proveedores: Proveedor[];
  
  constructor(private http: HttpClient) {
    /*this.productos = [
      {id:1, id_proveedor: 4, comerciante:"zapatos", sku:"GS-ZAP-001", nombre:"Zapatilla para novia 1", precio:123.49, categoria:"Calzado"},
      {id:2, id_proveedor: 4, comerciante:"zapatos", sku:"GS-ZAP-002", nombre:"Zapatilla para novia 2", precio:123.49, categoria:"Calzado"},
      {id:3, id_proveedor: 4, comerciante:"zapatos", sku:"GS-ZAP-003", nombre:"Zapatilla para novia 3", precio:123.49, categoria:"Calzado"},
      {id:4, id_proveedor: 2, comerciante:"greenconcept", sku:"GS-GCO-001", nombre:"Stevia Granulada", precio:123.49, categoria:"Alimentos alternativos"},
      {id:5, id_proveedor: 2, comerciante:"greenconcept", sku:"GS-GCO-002", nombre:"Stevia Líquida", precio:123.49, categoria:"Alimentos alternativos"},
      {id:6, id_proveedor: 2, comerciante:"greenconcept", sku:"GS-GCO-003", nombre:"Stevia Combo", precio:123.49, categoria:"Alimentos alternativos"},
      {id:7, id_proveedor: 3, comerciante:"joyeria", sku:"GS-TSO-001", nombre:"Collar dije C35", precio:123.49, categoria:"Joyería"},
      {id:8, id_proveedor: 3, comerciante:"joyeria", sku:"GS-TSO-002", nombre:"Collar dije Oro", precio:123.49, categoria:"Joyería"},
      {id:9, id_proveedor: 3, comerciante:"joyeria", sku:"GS-TSO-003", nombre:"Collar dije bronce", precio:123.49, categoria:"Joyería"},
      {id:10, id_proveedor: 1, comerciante:"lapikina", sku:"GS-PIK-001", nombre:"Salsa picante 250ml", precio:123.49, categoria:"Alimentos"},
      {id:11, id_proveedor: 1, comerciante:"lapikina", sku:"GS-PIK-002", nombre:"Polvo picante", precio:123.49, categoria:"Alimentos"},
      {id:12, id_proveedor: 1, comerciante:"lapikina", sku:"GS-PIK-003", nombre:"Salsa picante 1l", precio:123.49, categoria:"Alimentos"},
      {id:13, id_proveedor: 5, comerciante:"trazo", sku:"GS-TRA-001", nombre:"Diseño corporativo", precio:123.49, categoria:"Servicios creativos"},
      {id:14, id_proveedor: 5, comerciante:"trazo", sku:"GS-TRA-002", nombre:"Trabajo sobre vinil", precio:123.49, categoria:"Servicios creativos"},
      {id:15, id_proveedor: 5, comerciante:"trazo", sku:"GS-TRA-003", nombre:"Cuadros", precio:123.49, categoria:"Servicios creativos"},
    ];*/

    this.categorias = [
      {id: 1, etiqueta:"Todo", filtro:"todo", active:false},
      {id: 2, etiqueta:"Calzado", filtro:"calzado", active: false},
      {id: 3, etiqueta:"Alimentos", filtro:"alimentos", active: false},
      {id: 4, etiqueta:"Joyería", filtro:"joyería", active: false},
      {id: 5, etiqueta:"Decoración", filtro:"servicios", active: false}
    ];

    this.secciones = [
      {id: 1, etiqueta:"Todo", destino:"productos", ancla:""},
      {id: 2, etiqueta:"Calzado", destino:"proveedores", ancla:"/categoria/2"},
      {id: 3, etiqueta:"Alimentos", destino:"proveedores", ancla:"/categoria/3"},
      {id: 4, etiqueta:"Joyería", destino:"proveedores", ancla:"/categoria/4"},
      {id: 5, etiqueta:"Decoración", destino:"proveedores", ancla:"/categoria/5"}
    ]

    this.proveedores = [
      { id: 1, nombre: "La Pikina", alias:"lapikina", correo:"lapikina@trazo.us", logo:"lapikina.jpg", categoria: 3, uuid:null},
      { id: 2, nombre: "Green Concept", alias:"greenconcept", correo:"greenconcept@trazo.us", logo:"greenconcept.png", categoria: 3, uuid:null},
      { id: 3, nombre: "La Joyería", alias:"joyeria", correo:"joyeria@trazo.us", logo:"lajoyeria.jpg", categoria: 4, uuid:null},
      { id: 4, nombre: "La Zapatería", alias:"zapatos", correo:"zapatos@trazo.us", logo:"lazapateria.jpg", categoria: 2, uuid:null},
      { id: 5, nombre: "TRAZO Diseño", alias:"trazo", correo:"agustin@trazo.us", logo:"trazous.jpg", categoria: 5, uuid:null}
    ]
  }
  obtener_provedores():Observable<Proveedor[]>{
    return new Observable<Proveedor[]>((observer)=>{
      observer.next(this.proveedores);
      observer.complete();
    });
  }
  obtener_provedores_byid(id:number):Observable<Proveedor>{
    return new Observable<Proveedor>((observer)=>{
      observer.next(this.proveedores.filter(proveedor=>proveedor.id === id).pop());
      observer.complete();
    });
  }
  obtener_provedor_byid(id:number):Promise<Proveedor>{
    return new Promise<Proveedor>((resolve, reject)=>{
      resolve(this.proveedores.filter(proveedor=>proveedor.id === id).pop());
    });
  }
  obtener_provedores_bycategoria(id:number):Observable<Proveedor[]>{
    return new Observable<Proveedor[]>((observer)=>{
      this.http.get(`${environment.api_url}/proveedores`).subscribe({
        next: (provs)=>{
          this.proveedores = provs["result"];
          console.log("proveedores", this.proveedores, id);
          observer.next(this.proveedores.filter(prov=>parseInt(prov.alias)==id));
          observer.complete();
        }
      });
    });
  }
  obtener_provedores_byidlist(idlist:number[]):Observable<Proveedor[]>{
    return new Observable<Proveedor[]>((observer)=>{
      let proveedores: Proveedor[] = [] ;
      idlist.map((id_proveedor:number)=>{
        proveedores.push( this.proveedores.filter(proveedor=>proveedor.id === id_proveedor).pop() )
      });
      observer.next( proveedores );
      observer.complete();
    });
  }
  obtener_secciones():Observable<Seccion[]>{
    return new Observable<Seccion[]>((observer)=>{
      observer.next(this.secciones);
      observer.complete();
    });
  }

  all_Categories():Observable<Categoria[]>{
    return new Observable<Categoria[]>((observer)=>{
      observer.next(this.categorias);
      observer.complete();
    });
  }

  obtener_categorias_byproveedor(id:number):Observable<Categoria[]>{
    return new Observable<Categoria[]>((observer)=>{
      let categs = this.categorias.slice(0,1);
      categs[0].active = true;
      observer.next(categs);
      observer.complete();
    });
  }

  
  obtener_categoria_byid(id:number):Observable<Categoria>{
    return new Observable<Categoria>((observer)=>{
      observer.next(this.categorias.filter(categoria=>categoria.id === id).pop());
      observer.complete();
    });
  }

  all():Observable<Producto[]>{
    return new Observable<Producto[]>((observer)=>{
      this.http.get(`${environment.api_url}/productos`).subscribe({
        next: (prods)=>{
          console.log("prods", prods);
          this.productos = prods["result"];
          observer.next(this.productos);
          observer.complete();
        }
      });
    });
  }

  most_wanted(){
    return new Observable<Producto[]>((observer)=>{
      this.http.get(`${environment.api_url}/productos`).subscribe({
        next: (prods)=>{
          console.log("prods", prods);
          this.productos = prods["result"];
          var hot = this.productos.sort((prod_a:Producto, prod_b:Producto)=>prod_a.precio-prod_b.precio).slice(0,9);
          observer.next(hot);
          observer.complete();
        }
      });
    });
  }

  obtener(id:String): Observable<any> {
    return this.http.get(`${environment.api_url}/productos/${id}`);
  }
  obtener_por_categoria(categoria:string): Observable<Producto[]>{
    return new Observable<Producto[]>((observer)=>{
      this.http.get(`${environment.api_url}/productos`).subscribe({
        next: (prods)=>{
          this.productos = prods["result"];
          console.log("obtener_por_categoria", this.productos)
          let prodscateg = this.productos.filter((prod:Producto)=>prod.categoria.toLocaleLowerCase().includes(categoria.toLowerCase()));
          observer.next(prodscateg);
          observer.complete();
        }
      });
      
    });
  }

  obtener_productos_byproveedor(id:number): Observable<Producto[]>{
    console.log("obtener_productos_byproveedor", id);
    return new Observable<Producto[]>((observer)=>{
      this.http.get(`${environment.api_url}/productos`).subscribe({
        next: (prods)=>{
          this.productos = prods["result"];
          observer.next(this.productos.filter((prod)=>prod["ProveedoreId"]===id));
          observer.complete(); 
        }
      });
      
    });
  }

  obtener_by_sku(sku:String): Observable<Producto>{
    return new Observable<Producto>((observer)=>{
      this.http.get(`${environment.api_url}/productos`).subscribe({
        next: (prods)=>{
          this.productos = prods["result"];
          let prod = this.productos.find(prod=>prod["sku"]===sku)
          observer.next(prod);
          observer.complete();  
        }
      });
    });
  }
  
}