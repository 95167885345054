import { Component, Input, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {Categoria} from '../../models/categoria';
import {Router} from "@angular/router";
@Component({
  selector: 'app-menu-productos',
  templateUrl: './menu-productos.component.html',
  styleUrls: ['./menu-productos.component.less']
})
export class MenuProductosComponent implements OnInit {
  @Input() type: string;
  @Input() categorias: Categoria[];
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.router.navigate(["/productos",this.type]);
  }

  ngOnDestroy() {

  }

  onClick(categoria:Categoria){
    this.router.navigate(["/productos",categoria.filtro]);
  }
}
