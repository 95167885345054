import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CatalogoMosaicoComponent} from "./components/catalogo-mosaico/catalogo-mosaico.component";
import {PortadaComponent} from "./pages/portada/portada.component";
import {NotfoundComponent} from "./components/notfound/notfound.component";
import {LoginComponent} from "./pages/login/login.component";
import {SignupComponent} from "./pages/signup/signup.component";
import {AvisoprivacidadComponent} from "./pages/avisoprivacidad/avisoprivacidad.component";
import {TerminosycondicionesComponent} from "./pages/terminosycondiciones/terminosycondiciones.component";
import {ProductoComponent} from "./components/producto/producto.component";
import {CuentaComponent} from "./pages/cuenta/cuenta.component";
import {CartComponent} from "./pages/cart/cart.component";
import {CheckoutComponent} from "./pages/checkout/checkout.component";
import {ProductPreviewComponent} from "./components/product-preview/product-preview.component";
import {GraciasComponent} from "./pages/gracias/gracias.component";
import {ListaProveedoresComponent} from './components/lista-proveedores/lista-proveedores.component';
import {ProductosProveedorComponent} from './pages/productos-proveedor/productos-proveedor.component';
import { from } from 'rxjs';
 
const routes: Routes = [
  { path:'', redirectTo:'/portada', pathMatch:'full'},
  { path: 'portada', component: PortadaComponent },
  { path: 'registro', component: SignupComponent},
  { path: 'ingreso', component: LoginComponent},
  { path: 'cuenta', component: CuentaComponent},
  { path: 'cart', component: CartComponent},
  { path: 'cart/checkout', component: CheckoutComponent},
  { path: 'cart/checkout/:id_orden', component: GraciasComponent},
  { path: 'tyc', component: TerminosycondicionesComponent},
  { path: 'adp', component: AvisoprivacidadComponent},
  { path: 'productos', component: ProductoComponent},
  { path: 'productos/:type', component: ProductoComponent},
  { path: "productos/preview/:sku", component: ProductPreviewComponent, pathMatch: "full"},
  { path: "productos/proveedor/:id_proveedor", component: ProductosProveedorComponent},
  { path: "proveedores/categoria/:id_categoria", component: ListaProveedoresComponent},
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
