<div *ngIf="cart['productos'].length!==0; else emptyBag">
    <div class="row">
        <div class="col-12">
            <h1>Orden de compra</h1>
            <hr>
        </div>
    </div>
    <div class="checkouts">
        <style>
            .cart__header{
                color: gray;
            }
            .cart__data{
                border-bottom: solid 1px lightgray;
                padding: 1em;
            }
            .cart__products{
                border-bottom: solid 1px lightgray;
            }
            .product{
                border-bottom: dotted lightgray 1px;
            }
            .row{
                width: 100%;
            }
            .col, .col-12{
                width: 100%;
                margin: auto auto;
            }
            .col-1{
                width: calc(100%/12);
                margin: auto auto;
            }
            .col-2{
                width: calc( (100%/12)* 2 );
                margin: auto auto;
            }
            .col-3{
                width: calc( (100%/12)* 3 );
                margin: auto auto;
            }
            .col-4{
                width: calc( (100%/12)* 4 );
                margin: auto auto;
            }
            .col-5{
                width: calc( (100%/12)* 5 );
                margin: auto auto;
            }
            .col-6{
                width: calc( (100%/12)* 6 );
                margin: auto auto;
            }
            .col-7{
                width: calc( (100%/12)* 7 );
                margin: auto auto;
            }
            .col-8{
                width: calc( (100%/12)* 8 );
                margin: auto auto;
            }
            .col-9{
                width: calc( (100%/12)* 9 );
                margin: auto auto;
            }
            .col-10{
                width: calc( (100%/12)* 10 );
                margin: auto auto;
            }
            .col-11{
                width: calc( (100%/12)* 11 );
                margin: auto auto;
            }
            .w-25{
                width: 25%;
            }
            .d-flex{
                display: flex;
            }
            .ml-auto{
                margin-left: auto;
            }
            img{
                border: solid lightgray 1px;
                border-radius: 50%;
                width: 100%;
            }
            figcaption{
                color: gray;
                font-size: 10px;
            }
            .facturas{
                /*display: none;*/
            }
        </style>
        <div class="facturas d-none">
            <div class="row factura" *ngFor="let item of cart_byprov" [attr.data-proveedor]="item.proveedor.id">
                <div class="row cart__data">
                    <h3>Datos de compra</h3>
                    <div class="col-12">
                        <table cellspacing="1" style="width:50%">
                            <tbody>
                                <tr>
                                    <th>Comprador:</th>
                                    <td>{{nombre}}</td>
                                </tr>
                                <tr>
                                    <th>Contacto:</th>
                                    <td>{{correo}}</td>
                                </tr>
                                <tr>
                                    <th>Fecha:</th>
                                    <td>{{fecha_compra}}</td>
                                </tr>
                                <tr>
                                    <th>Total a cobrar:</th>
                                    <td>{{number2amount(item.total*1.16)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row cart__products">
                    <div class="col">
                        <h3>Productos</h3>
                        <table cellspacing="1" style="width:100%">
                            <thead>
                                <th>SKU</th>
                                <th>Cantidad</th>
                                <th>Descripción</th>
                                <th>Subtotal</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let itemprod of item.productos">
                                    <td>{{itemprod.producto.sku}}</td>
                                    <td>{{itemprod.cantidad}}</td>
                                    <td>{{itemprod.producto.nombre}}</td>
                                    <td>{{number2amount(itemprod.cantidad * itemprod.producto.precio)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr>
                <div class="row cart__summary">
                    <div class="col-12 summary">
                        <h3>Resumen</h3>
                        <table cellspacing="1" style="width:30%">
                            <tbody>
                                <tr>
                                    <th>Sub total:</th>
                                    <td>{{number2amount(item.total)}}</td>
                                </tr>
        
                                <tr>
                                    <th>I.V.A:</th>
                                    <td>{{number2amount(item.total*.16)}}</td>
                                </tr>
                                <tr>
                                    <th>Total:</th>
                                    <td>{{number2amount(item.total*1.16)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="checkoutall">
        <div class="row cart__data">
            <h3>Datos de compra</h3>
            <div class="col-12">
                <table cellspacing="1" style="width:50%">
                    <tbody>
                        <tr>
                            <th>Contacto:</th>
                            <td>{{correo}}</td>
                        </tr>

                        <tr>
                            <th>Fecha:</th>
                            <td>{{fecha_compra}}</td>
                        </tr>
                        <tr>
                            <th>Total a pagar:</th>
                            <td>{{number2amount(cart['total']*1.16)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row cart__products">
            <div class="col">
                <h3>Productos</h3>
                <table cellspacing="1" style="width:100%">
                    <thead>
                        <th>SKU</th>
                        <th>Cantidad</th>
                        <th>Descripción</th>
                        <th>Subtotal</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of cart['productos']">
                            <td>{{item.producto.sku}}</td>
                            <td>{{item.cantidad}}</td>
                            <td>{{item.producto.nombre}}</td>
                            <td>{{number2amount(item.cantidad * item.producto.precio)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row cart__summary">
            <div class="col-12 summary">
                <h3>Resumen</h3>
                <table cellspacing="1" style="width:30%">
                    <tbody>
                        <tr>
                            <th>Sub total:</th>
                            <td>{{number2amount(cart['total'])}}</td>
                        </tr>

                        <tr>
                            <th>I.V.A:</th>
                            <td>{{number2amount(cart['total']*.16)}}</td>
                        </tr>
                        <tr>
                            <th>Total:</th>
                            <td>{{number2amount(cart['total']*1.16)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row cart_actions">
        <div class="col-12 d-flex">
            <div class="w-25 ml-auto d-flex">
                <button (click)="regresar()" class="btn btn-light">Modificar bolsa</button>
                <button (click)="checkout()" class="btn btn-success ml-auto">Ordenar bolsa</button>
            </div>
        </div>
    </div>
</div>
<ng-template #emptyBag class="row cart__empty" >
    <div class="col">
        <h1>Bolsa vacía :(</h1>
        <br>
        <p>Contamos con una ámplia variedad de productos, listos para agregar a su bolsa.</p>
    </div>
    
</ng-template>
