<app-carousel></app-carousel>
<hr>
<div class="row">
    <div class="col text-center">
        <h3>Destacados</h3>
    </div>
</div>
<hr>
<div class="row d-flex productos__circulo">
    <div *ngFor="let producto of destacados" class="col-4 text-center producto" (click)="producto_selected(producto)">
        <figure>
            <img src="assets/images/products/{{producto.Proveedore.uuid}}/{{producto.sku}}.jpg">
            <figcaption>
                <p><span class="fa fa-clock"></span>{{producto.nombre}}</p>
            </figcaption>
        </figure>
    </div>
</div>
