import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UsersService} from '../../services/users/users.service';
declare let alertify: any;
@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.less']
})
export class CuentaComponent implements OnInit {
  nombre: String = "";
  email: String = "";
  clave: String = "";
  constructor(private router: Router, public userService: UsersService) { }

  ngOnInit(): void {
    let userid = sessionStorage.getItem("userid");
    if(!userid){
      this.router.navigate(["/ingreso"]);
    } else{
      this.userService.obtener(userid).subscribe(response=>{
        if(response.done){
          this.nombre = response.result.nombre;
          this.email = response.result.correo;
          this.clave = "******";
        }
        /*if(response.done){
          alertify.success("usuario registrado correctamente");
          this.router.navigate(["/ingreso"],{queryParams:{"correo": this.email}});
        } else{
          if(response.code == "fail"){
            if(response.data.errno == 1062){
              alertify.error(`El correo ${this.email} ya existe. Por favor inicie sesión o restablezca su contraseña.`);
            } else{
              alertify.error(response.data.code);
            }
          } else{
            var campos = [];
            for(var campo in response.data){
              campos.push(response.data[campo]);
            }
            alertify.error("No se pudo registrar correctamente:<br><pre>"+ campos.join("<br>")+"</pre>");
          }
        }*/
      });
    }
  }

  actualizar(){

  }

  logout(){
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("username");
    alertify.success("Se ha cerrado su sesión");
    this.router.navigate(["/"]);
  }

}
