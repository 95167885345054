import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Categoria } from 'src/app/models/categoria';
import { Producto } from 'src/app/models/producto';
import { Proveedor } from 'src/app/models/proveedor';
import { ProductoService } from 'src/app/services/productos/products.service';

@Component({
  selector: 'app-productos-proveedor',
  templateUrl: './productos-proveedor.component.html',
  styleUrls: ['./productos-proveedor.component.less']
})
export class ProductosProveedorComponent implements OnInit {
  id_proveedor: number;
  proveedor:Proveedor;
  productos:Producto[];
  categorias:Categoria[];
  constructor(private route:ActivatedRoute, private router:Router, public producto_srv:ProductoService) { }

  ngOnInit(): void {
    this.route.params.subscribe({
      next:(params:any)=>{
        this.id_proveedor = parseInt(params["id_proveedor"]);
        this.producto_srv.obtener_provedores_byid(this.id_proveedor).subscribe({
          next:(proveedor:Proveedor)=>{
            this.proveedor = proveedor;
            this.producto_srv.obtener_categorias_byproveedor(proveedor.id).subscribe({
              next:(categorias:Categoria[])=>{
                this.categorias = categorias;
                this.producto_srv.obtener_productos_byproveedor(this.id_proveedor).subscribe({
                  next:(productos:Producto[])=>{
                    this.productos = productos;
                    console.log("productos", this.productos);
                  }
                });
              }
            })
            
          }
        })
      }
    })
  }

}
