<h1>{{categoria.etiqueta}}</h1>
<div class="row catalogo">
    <div *ngFor="let proveedor of proveedores" class="col-4 proveedor" (click)="selected(proveedor)">
        <div class="card producto">
            <img class="card-img" src="assets/images/proveedores/{{proveedor.uuid}}.jpg" alt="Proveedor">
            <div class="card-body">
              <h4 class="card-title">{{proveedor.nombre}}</h4>
              <h6 class="card-subtitle mb-2 text-muted">ID: {{proveedor.alias}}</h6>
              <p class="card-text">{{proveedor.correo}}</p>
            </div>
        </div>
    </div>
</div>