import { Component, OnInit } from '@angular/core';
import { TriviaSyntaxKind } from 'typescript';

@Component({
  selector: 'app-avisoprivacidad',
  templateUrl: './avisoprivacidad.component.html',
  styleUrls: ['./avisoprivacidad.component.less']
})
export class AvisoprivacidadComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
