<div *ngIf="cart['productos'].length!==0; else emptyBag">
    <div class="row cart__products">
        <div class="col">
            <h1>Tu bolsa</h1>
            <hr>
            <h4>Productos</h4>
            <div class="row product d-flex" *ngFor="let item of cart['productos']">
                <div class="col-1">
                    <figure>
                        <img src="assets/images/products/{{item.producto.Proveedore.uuid}}/{{item.producto.sku}}.jpg" alt="" srcset="">
                        <figcaption class="text-center">
                            {{item.producto.sku}}
                        </figcaption>
                    </figure>
                </div>
                <div class="col-2">
                    <input type="number" value="{{item.cantidad}}" disabled>
                </div>
                <div class="col-4">
                    {{item.producto.nombre}}
                </div>
                <div class="col-2">
                    {{number2amount(item.cantidad * item.producto.precio)}}
                </div>
                <div class="col-1">
                    <button class="btn btn-danger" (click)="removeFromCart(item)">
                        <span class="fa fa-trash"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row cart__summary">
        <div class="col-12 summary">
            <h4>Resumen</h4>
            <div class="pull-right w-25">
                <div class="d-flex">
                    <div>Sub total</div>
                    <div class="ml-auto">{{number2amount(cart['total'])}}</div>
                </div>
                <div class="d-flex">
                    <div>I.V.A:</div>
                    <div class="ml-auto">{{number2amount(cart['total']*.16)}}</div>
                </div>
                <div class="d-flex">
                    <div><h4>Total:</h4></div>
                    <div class="ml-auto"><h3>{{number2amount(cart['total']*1.16)}}</h3></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row cart_actions">
        <div class="col-12 d-flex">
            <div class="w-25 ml-auto d-flex">
                <button (click)="vaciar()" class="btn btn-light">Vaciar bolsa</button>
                <button (click)="comprar()" class="btn btn-success ml-auto">Comprar</button>
            </div>
        </div>
    </div>
</div>
<ng-template #emptyBag class="row cart__empty" >
    <div class="col">
        <h1>Bolsa vacía :(</h1>
        <br>
        <p>Contamos con una ámplia variedad de productos, listos para agregar a su bolsa.</p>
    </div>
    
</ng-template>
