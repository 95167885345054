import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {ProductoService} from '../../services/productos/products.service';
import {Seccion} from '../../models/seccion'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  secciones:Seccion[];
  constructor(public producto_srv: ProductoService) { }
  ngOnInit(): void {
    this.producto_srv.obtener_secciones().subscribe({
      next: (categorias:Seccion[])=>{
        this.secciones = categorias;
        
      },
      error: ()=>{

      }
    })
  }

  menu(evt){
    
  }

}
