<h1>Aviso de privacidad</h1>
<p>El aviso de privacidad es un documento que deben elaborar las personas físicas (abogados, médicos, dentistas) o morales (empresas, organizaciones o sociedades) que recopilen información personal de las personas a las que les ofrecen sus productos y/o servicios.

Por ley, el aviso de privacidad debe ponerse a disposición de los interesados, estar publicado y difundirse a efecto de que las personas (titulares de los datos personales) puedan conocerlo y en su caso oponerse al uso, tratamiento y/o transferencia de sus datos.


¿Cómo utilizar este documento?

Este documento está diseñado para elaborar un aviso de privacidad integral y pueden señalarse los datos personales que serán recabados así como los fines por los que se recopilan los datos personales.

El documento incluye las adaptaciones que deben realizarse en caso de que los datos personales sean compartidos con un tercero.

Así mismo, este documento se encuentra adaptado para especificar:

- Los medios por los que las personas podrán ejercitar sus derechos

- La forma en la que los titulares podrán informarse de las modificaciones al aviso de privacidad

- Los espacios necesarios para que las personas puedan otorgar su consentimiento expreso y por escrito en caso de que se proporcionen datos personales sensibles

Respecto a este último punto es importante precisar que los datos personales sensibles son datos personales que pueden dar origen a conductas discriminatorias. En particular se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual. Por lo cual, hay que tener en cuenta que si va a recabar datos personales sensibles, por ley se requiere obtener la autorización expresa y por escrito de la persona que otorga sus datos personales, y dicha autorización debe incluir autógrafa o en su caso la firma electrónica del titular de los datos personales.

Una vez elaborado el aviso de privacidad usualmente este se pone a disposición de los titulares (las personas que otorgan o facilitan sus datos personales) de la siguiente forma:

a) Cuando los datos personales sean obtenidos en parecencia del titular, el aviso de privacidad es facilitado en el momento en que se recaban los datos, de forma clara y fehaciente, a través de los mismos formatos o medios por los que se recaban los datos. Para estos casos, el modelo ya incluye los espacios necesarios para la firma del titular cuando esta es requerida, específicamente cuando se recaban datos personales sensibles.

b) Cuando los datos personales sean obtenidos sin parecencia del titular, como por ejemplo a través de internet o por teléfono (la ley incluye además medios ópticos, sonoros, visuales, o a través de cualquier otra tecnología). En estos casos, se proporciona al menos: la identidad y el domicilio de quien recaba los datos así como los fines para los que serán utilizados y los mecanismos para que el titular conozca el texto completo del aviso de privacidad.


Es importante mencionar que el documento cuenta con todos los requisitos establecidos en la ley, específicamente en el artículo 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares.
</p>